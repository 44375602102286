import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {Organization, Permission} from "Models";
import {download} from "Services/OrganizationThumbnail.service";

export interface CreateOrgArgs
{
	name: string
	description: string
}

export interface DeleteOrgArgs
{
	id: string
	orgNameQuestion: string
}

export interface UpdateOrgArgs
{
	id: string
	name: string
	description: string
}

export interface UploadThumbnailArgs
{
	id: string
	file: Blob
}

const baseUrl = "Organizations"

function checkFlag(organization: Organization, flag: Permission): boolean
{
	if (!organization)
		return false;

	const permissions = organization.permissions;
	return (permissions & flag) == flag;
}

export default {
	list: createAsyncThunk<Organization[], void>(`${baseUrl}/list`, async (arg, thunkAPI) =>
	{
		async function fetchAndConfig(org: Organization)
		{
			const blob = await download(org.id);
			org.thumbnailUrl = URL.createObjectURL(blob);

			// org.viewPortal = checkFlag(org, Permission.PortalView);
			// org.editPortal = checkFlag(org, Permission.PortalManage);
		}

		const orgList = await axios.get<Organization[]>(baseUrl).then(r => r.data)
		await Promise.allSettled(orgList.map(fetchAndConfig))

		return orgList
	}),

	create: createAsyncThunk<Organization, CreateOrgArgs>(`${baseUrl}/create`, (arg) =>
	{
		return axios.post<Organization>(baseUrl, arg).then(r => r.data)
	}),

	delete: createAsyncThunk<Organization, DeleteOrgArgs>(`${baseUrl}/delete`, (arg) =>
	{
		const {id, orgNameQuestion} = arg;
		return axios.delete<Organization>(`${baseUrl}/${id}?orgNameQuestion=${orgNameQuestion}`).then(r => r.data)
	}),

	update: createAsyncThunk<void, UpdateOrgArgs>(`${baseUrl}/update`, (arg) =>
	{
		let {id, ...body} = arg;
		return axios.put<void>(`${baseUrl}/${id}`, body).then(r => r.data)
	})
}