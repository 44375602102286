import {useEffect, useState} from "react";
import {useIsAuthenticated} from "@azure/msal-react"
import {getOrgStats} from "Store/currentOrgStats.slice";
import {useAppDispatch, useAppSelector} from "Store/hooks";

import PlansRemote from "Store/async/plans.remote";
import LicenseRemote from "Store/async/license.remote";
import OrgUsersRemote from "Store/async/orgUsers.remote";
import InstallersRemote from "Store/async/installers.remote";
import OrganizationsRemote from "Store/async/organizations.remote";

import {selectActiveOrg, selectIsAuthDataReady} from "Store/selectors";
import useIsAdmin from "Hooks/useIsAdmin.hook";
import useLoadingProgress from "Hooks/useLoadingProgress";
import PermissionUtils from "Utilities/permissionUtils"
import {appActions} from "Store/appStateSlice";

export default function Initialization() {
	const dispatch = useAppDispatch();
	const activeOrg = useAppSelector(selectActiveOrg)

	const isAdmin = useIsAdmin()
	const isAuthenticated = useIsAuthenticated()
	const loadingProgress = useLoadingProgress()
	const isAuthDataReady = useAppSelector(selectIsAuthDataReady);

	const [isOrgStateLoaded, setIsOrgStateLoaded] = useState(false)
	const [isAppBaseStateLoaded, setIsAppBaseStateLoaded] = useState(false)

	useEffect(() => {
		if (!isAuthDataReady && isAppBaseStateLoaded && isOrgStateLoaded) {
			dispatch(appActions.setInitializationReady())
		}
	}, [isAuthDataReady, isAppBaseStateLoaded, isOrgStateLoaded])

	// Fetch static shit
	useEffect(() => {
		if (!isAuthenticated || isAdmin === null)
			return;

		const promiseList: Promise<any>[] = [
			dispatch(OrganizationsRemote.list())
		]

		if (isAdmin) {
			promiseList.push(...[
				dispatch(PlansRemote.list()),
				dispatch(InstallersRemote.list()),
			])
		}

		loadingProgress.setIsLoading(true)
		Promise
			.all(promiseList)
			.finally(() => {
				setIsAppBaseStateLoaded(true)
				loadingProgress.setIsLoading(false)
			})
	}, [isAuthenticated, isAdmin])

	// Fetch active org info
	useEffect(() => {
		if (!isAuthenticated || !isAppBaseStateLoaded)
			return;

		if (!activeOrg || !PermissionUtils.hasView(activeOrg.permissions)) {
			setIsOrgStateLoaded(true)
		}
		else {

			const promiseList = [
				dispatch(OrgUsersRemote.list({orgId: activeOrg.id})),
				dispatch(LicenseRemote.get(activeOrg.id)).unwrap()
					.then(license => license && dispatch(getOrgStats(license.id)))
			]

			setIsOrgStateLoaded(false)
			loadingProgress.setIsLoading(true)

			Promise
				.all<any>(promiseList)
				.finally(() => {
					setIsOrgStateLoaded(true)
					loadingProgress.setIsLoading(false)
				})
		}
	}, [isAppBaseStateLoaded, isAuthenticated, activeOrg])

	return null;
}