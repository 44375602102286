import {Formik, Form, Field, ErrorMessage, FormikHelpers} from "formik"
import {PageContent, PageTitle} from "Layouts/Default"
import * as Yup from 'yup'
import useLoadingProgress from "Hooks/useLoadingProgress"
import styled from "styled-components";
import {useAppSelector} from "Store/hooks";
import {useViewMode, ViewMode} from "Providers/ViewModeContextProvider";
import Panel from "Components/Panel";
import downloadBlobWithAuth from "Utilities/downloadBlobWithAuth"

import ActivePlanEditor from "./ActivePlanEditor";
import ActivePlanReadonly from "./ActivePlanReadOnly";

import {AudioUsageGraphPanel, VideoUsageGraphPanel, EditorUsageGraphPanel, PlayerUsageGraphPanel} from "./Panels"
import {ViewModeProps} from "../../routes";
import {selectActiveOrg, selectActiveOrgLicense} from "../../Store/selectors";

const LicensePageStyles = styled.div`

  // Being used by children elements to enforce a max-height property 
  --grid-item-height: 21rem;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: var(--grid-item-height);

  //height: 100%;

  .panel-root {
    min-width: 360px;
  }
`

export default function LicensePage(props: ViewModeProps) {
	const activeLicense = useAppSelector(s => s.organizationLicense.current);
	
	return (
		<>
			<PageTitle> License </PageTitle>
			<PageContent>
				<LicensePageStyles>
					<PlanPanel/>

					{
						activeLicense && !activeLicense.isSubscription && (<OfflineLicenseGeneratorPanel edit={props.edit}/>)
					}
					<AudioUsageGraphPanel/>
					<VideoUsageGraphPanel/>

					<EditorUsageGraphPanel/>
					<PlayerUsageGraphPanel/>
				</LicensePageStyles>
			</PageContent>
		</>
	)
}

function PlanPanel() {
	const viewMode = useViewMode()

	const activeLicense = useAppSelector(s => s.organizationLicense.current);

	return (
		<Panel title="Active Plan">
			{
				viewMode.mode == ViewMode.Admin && !activeLicense?.isSubscription
					? <ActivePlanEditor/>
					: <ActivePlanReadonly/>
			}
		</Panel>
	)
}

const PanelStyled = styled(Panel)`
  // Centralize the form
  .panel-content {
    display: grid;
    place-items: center;
  }

  form {
    position: relative;
    display: grid;
    gap: 0.5rem;

    // Must use the * selector to affect every children
    * {
      font-size: 1.2rem;
    }

    div {
      display: grid;
      //width: 100%;
      //height: 100%;
      background-color: #444;
      gap: 1rem;
      padding: 0.9rem 2rem;
      width: 48ch;
      height: 215px;
      margin: auto;
    }

    textarea {
      // Firefox is adding an extra space when user starts writing on it,
      //  enforcing a width fixes that
      width: 100%;
      height: 33px;
      margin: auto;
      text-align: center;
      resize: none;
    }

    button {
      width: 200px;
      margin: auto;
    }

    .error-message {
      position: absolute;

      left: 50%;
      bottom: calc(100% + 0.5rem);

      transform: translateX(-50%);

      font-size: 1rem;
      line-height: 1rem;

      color: var(--red-custon);
      font-style: italic;

      white-space: nowrap;
    }
  }
`

const LicenseFormSchema = Yup.object().shape({
	deviceId: Yup.string()
		.required()
		.length(40)
})

function OfflineLicenseGeneratorPanel(props: ViewModeProps) {
	const progress = useLoadingProgress()
	const activeLicense = useAppSelector(selectActiveOrgLicense)
	const organization = useAppSelector(selectActiveOrg);

	function onFormSubmit(values: any, helpers: FormikHelpers<any>) {

		const deviceId = values.deviceId
		const licenseId = activeLicense!.id

		progress.start()
		downloadBlobWithAuth(`Client/Licenses/${licenseId}/GenerateCode/${deviceId}`, 'license.lic')
			.finally(() => {
				progress.stop()
				helpers.setSubmitting(false)
			})
	}

	return (
		<PanelStyled title="Offline Activation">
			<Formik initialValues={{deviceId: ''}} onSubmit={onFormSubmit} validationSchema={LicenseFormSchema}>
				<Form>
					<ErrorMessage name="deviceId" className="error-message" component="p"/>
					<div>
						<Field name="deviceId" component="textarea" placeholder="Device ID..." disabled={!props.edit}/>
					</div>
					<button type="submit" disabled={!props.edit}> Generate</button>
				</Form>
			</Formik>
		</PanelStyled>
	)
}